@use './colors.scss';
@use './font-weights.scss';

body {
  font-family: 'FuturaAllele', sans-serif;
  font-weight: font-weights.$font-weight-book;
  font-size: 16px;
  line-height: 24px;
  color: colors.$nero;
}

/* Removes the various defaults on form elements and buttons that many browsers apply from their
user agent stylesheet. Instead treat like any other element and use inheritance from the parent.
*/
button,
input,
select,
textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

b,
strong {
  font-weight: font-weights.$font-weight-demibold;
}

.nv-text-red {
  color: colors.$error;
}
