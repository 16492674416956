@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.tatTag {
  background-color: $prim;
  color: $mulberry;
  font-size: 16px;
  padding: 3px 9px;
  display: inline-flex;
  border-radius: 4px;

  @include screen-extra-large {
    font-size: 14px;
    padding: 0 5px;
  }
}

.tatIcon {
  display: inline;
  margin: auto 5px auto 0;

  @include screen-extra-large {
    width: 14px;
    height: 14px;
  }
}
