@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.sponsoredTag {
  border: $ocean 1px solid;
  color: $ocean;
  border-radius: 15px;
  font-weight: $font-weight-demibold;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  padding: 1px 10px;
}

.tooltip {
  max-width: 335px;
}
