@use './breakpoints.scss';

.nv-markdown {
  img {
    inline-size: 100%;

    @include breakpoints.screen-large {
      inline-size: initial;
    }
  }
}
