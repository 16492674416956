/* stylelint-disable no-descending-specificity */

@use './colors.scss';
@use './font-weights.scss';

@import './spinner-circle.scss';

/* Override the default link colors */
.nv-button,
a.nv-button:link,
a.nv-button:visited {
  color: colors.$white;
  text-decoration: none;
}

/* All buttons (primary and secondary)
   ----------------------------------------------------------------- */

.nv-button {
  display: inline-flex;
  padding-inline: 30px;
  font-weight: font-weights.$font-weight-demibold;
  font-size: 16px;
  line-height: 46px;
  letter-spacing: 1px;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  background-color: colors.$deepTeal;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;

  .nv-spinner-circle {
    margin-inline-start: 10px;
  }
}

.nv-button-loading {
  cursor: not-allowed;
}

.nv-button-active,
.nv-button:hover,
.nv-button:active {
  color: colors.$white;
  background-color: colors.$eden;
}

/* Secondary buttons
   ----------------------------------------------------------------- */
/* Also, override the default anchor element colors for secondary buttons */

.nv-button-secondary,
a.nv-button-secondary:link,
a.nv-button-secondary:visited {
  color: colors.$deepTeal;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px colors.$deepTeal;
}

.nv-button-secondary.nv-button-active,
.nv-button-secondary:hover,
.nv-button-secondary:active,
a.nv-button-secondary:hover,
a.nv-button-secondary:active {
  color: colors.$white;
  background-color: colors.$eden;
  box-shadow: none;

  .nv-spinner-circle {
    @include spinnerColor(colors.$white);
  }
}

/* Ghost buttons
   ----------------------------------------------------------------- */
/* Also, override the default anchor element colors for ghost buttons */

.nv-button-ghost,
a.nv-button-ghost:link,
a.nv-button-ghost:visited {
  color: colors.$ocean;
  background-color: transparent;
}

.nv-button-ghost.nv-button-active,
.nv-button-ghost:hover,
.nv-button-ghost:active,
a.nv-button-ghost:hover,
a.nv-button-ghost:active {
  color: colors.$cobalt;
  background-color: colors.$gray5;
}

/* White buttons
   ----------------------------------------------------------------- */
/* Also, override the default anchor element colors for white buttons */

.nv-button-white,
a.nv-button-white:link,
a.nv-button-white:visited {
  color: colors.$nero;
  background-color: colors.$white;
}

/* White button - hover state */
.nv-button-white.nv-button-active,
.nv-button-white:hover,
.nv-button-white:active {
  color: colors.$nero;
  background-color: colors.$white;
  opacity: 0.8;
}

/* White secondary buttons
   -----------------------------------------------------------------  */

/* IMPORTANT!!!
--------------------------------------------------------------------
All the nv-button-* classes with their pseudo-classes should be defined
before nv-button-secondary-white. Other way it will be overridden by them.
*/

.nv-button-secondary-white,
a.nv-button-secondary-white:link,
a.nv-button-secondary-white:visited {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px colors.$white;
  color: colors.$white;
}

/* Secondary white button - hover state */
.nv-button-secondary-white.nv-button-active,
.nv-button-secondary-white:hover,
.nv-button-secondary-white:active,
a.nv-button-secondary-white:hover,
a.nv-button-secondary-white:active {
  color: colors.$nero;
  background-color: colors.$white;

  .nv-spinner-circle {
    @include spinnerColor(colors.$nero);
  }
}

/* Plain action (buttons that look like plain inline text)
-----------------------------------------------------------------
Differences from links:
- are used to perform an action on a page instead of linking to another page
- color is "deep teal" instead of "ocean" color (green instead of blue)
- does not have an underline (but the hover state is underlined)
- are given an onClick handler — instead of a href URL
- use a <button> element instead an <a> element

Differences from buttons:
- looks like plain text instead of a button
- does not have a fixed height (buttons have a height of 46px)
- inherit the natural font-size and line-height (defaults to the body’s 16px/24px)
- have a hover state with underlined text instead of a background color change
- are often used in the middle of, or at the the end, of a sentence of text
*/
.nv-plain-action {
  display: inline-flex;
  cursor: pointer;
  color: colors.$deepTeal;
  align-items: center;

  .nv-spinner-circle {
    margin-inline-start: 10px;
  }
}

.nv-plain-action:hover {
  text-decoration: underline;
}

/* Focus state
   ----------------------------------------------------------------- */
.nv-button:focus {
  outline: 2px solid colors.$cooledCarbon;
  outline-offset: 2px;
}

.nv-button-white:focus,
.nv-button-secondary-white:focus {
  outline: 2px solid colors.$white;
}

/* Disabled state
   ----------------------------------------------------------------- */
.nv-button--disabled,
.nv-button--disabled:hover,
.nv-button:disabled,
.nv-button:hover:disabled,
.nv-button[aria-disabled='true'],
.nv-button[aria-disabled='true']:hover {
  background-color: colors.$gray3;
  cursor: not-allowed;
}

/* Disabled white button */
.nv-button-white.nv-button--disabled,
.nv-button-white.nv-button--disabled:hover,
.nv-button-white:disabled,
.nv-button-white:hover:disabled,
.nv-button-white:active:disabled,
.nv-button-white[aria-disabled='true'],
.nv-button-white[aria-disabled='true']:hover,
.nv-button-white[aria-disabled='true']:active {
  color: colors.$white;
  opacity: 1;
  cursor: not-allowed;
}

/* Disabled secondary button & disabled secondary white button */
.nv-button-secondary.nv-button--disabled,
.nv-button-secondary.nv-button--disabled:hover,
.nv-button-secondary:disabled,
.nv-button-secondary:hover:disabled,
.nv-button-secondary-white:disabled,
.nv-button-secondary-white:hover:disabled,
.nv-button-secondary[aria-disabled='true'],
.nv-button-secondary[aria-disabled='true']:hover,
.nv-button-secondary-white[aria-disabled='true'],
.nv-button-secondary-white[aria-disabled='true']:hover {
  color: colors.$gray3;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px colors.$gray3;
  cursor: not-allowed;

  .nv-spinner-circle {
    @include spinnerColor(colors.$gray3);
  }
}
/* Size variations: large (default), medium, or small
   ----------------------------------------------------------------- */
/* Medium size buttons */
.nv-button-medium {
  padding-inline: 25px;
  line-height: 36px;
}

/* Small size buttons */
.nv-button-small {
  padding-inline: 16px;
  line-height: 30px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

/* suffix and prefix */
.nv-form-prefix {
  display: flex;
  justify-content: center;
  margin-inline-end: 10px;
  min-inline-size: 20px;
}

.nv-form-suffix {
  display: flex;
  justify-content: center;
  margin-inline-start: 10px;
  min-inline-size: 20px;
}
