@use './breakpoints.scss';
@use './colors.scss';

.nv-input-search {
  display: flex;
  border-radius: 3px;
  block-size: fit-content;
  inline-size: 100%;

  .nv-form-input {
    inline-size: 100%;

    @include breakpoints.screen-medium {
      min-inline-size: 150px;
    }

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  .nv-form-input-suffix {
    display: flex;
    align-items: center;

    path {
      fill: colors.$deepTeal;
    }
  }

  @include breakpoints.screen-medium {
    align-self: flex-start;
  }
}
