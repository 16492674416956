/* Invitae Design System reset

This reset stylesheet has three purposes:
1. Removes all platform-specific or browser-specific styling on basic elements
2. Embraces and codifies the most common and prevalent usage of basic elements
3. Establishes a clean, simple, and minimal foundation to build design systems

1. Removes all spacing
--------------------------------
Make everything appear like a generic, appearance-less, style-less block element
by removing styles added by the user agent stylesheet. Some examples are:
- Removes margin and padding (all elements)
- Removes font size and weight (headings)
- Removes border and background (form elements)
- Removes alignment (table cells and headings)

2. Embraces good defaults
--------------------------------
Some default element styles remain unchanged from decades ago when web pages
looked very different. On a modern accessible ecommerce website, with semantic
markup and separation of content and presentation, the most prevalent usage of
basic elements has changed. Some examples of new defaults set herein are:
- Box model set to border-box
- Lists set to have no style
- Images and SVG images set to block level elements
- Tables set to the collapsed-border rendering model

3. Foundation for design systems
--------------------------------
After that, the other files in this package provide the styles for each design
system pattern (buttons, headings, form fields, lists, etc).

This is in contrast to other open-source reset CSS files that provide their own
opinionated theme, defaults, or browser normalizations within a reset file.
*/

// stylelint-disable plugins/declaration-use-variable
*,
::before,
::after {
  box-sizing: border-box;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-size-adjust: 100%;
}

iframe {
  appearance: none;
  border: none;
}

img,
svg {
  display: block;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  font-weight: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: normal;
  text-align: start;
}

th,
td {
  vertical-align: top;
}

fieldset,
legend {
  border: 0;
}

button,
input,
select,
textarea {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none; /* firefox, edge, chrome, and safari all support -webkit-appearance: none */
  padding-block: 0;
  padding-inline: 0;
  border: none;
  background: none;
  border-radius: 0;
}

dialog {
  block-size: unset;
  border: unset;
  inline-size: unset;
  inset-inline: unset;
  margin: unset;
  padding: unset;
  position: unset;
}

sup {
  line-height: 0;
}

// stylelint-enable plugins/declaration-use-variable
