@use './font-weights.scss';

/* font style: normal (4 font weights)
   ----------------------------------------------------------------- */
@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-weight: font-weights.$font-weight-light;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-Light.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-weight: font-weights.$font-weight-book;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-Book.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-weight: font-weights.$font-weight-medium;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-Medium.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-weight: font-weights.$font-weight-demibold;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-DemiBold.woff');
}

/* font style: italic (4 font weights)
   ----------------------------------------------------------------- */
@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-style: italic;
  font-weight: font-weights.$font-weight-light;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-LightOblique.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-style: italic;
  font-weight: font-weights.$font-weight-book;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-BookOblique.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-style: italic;
  font-weight: font-weights.$font-weight-medium;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-MediumOblique.woff');
}

@font-face {
  font-display: swap;
  font-family: 'FuturaAllele';
  font-style: italic;
  font-weight: font-weights.$font-weight-demibold;
  src: url('https://casa.invitae.com/dist/fonts/futura-allele/2/FuturaAllele-DemiBoldOblique.woff');
}
