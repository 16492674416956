@use './breakpoints.scss';

/* Containers
   ----------------------------------------------------------------- */
.nv-container {
  margin-inline: 15px;

  @include breakpoints.screen-medium {
    margin-inline: auto;
    inline-size: 690px;
  }

  @include breakpoints.screen-large {
    inline-size: 990px;
  }

  @include breakpoints.screen-extra-large {
    inline-size: 1230px;
  }
}
