// Note - when importing CSS files, use `@use` instead of `@import` to guarantee rules are bundled into this file
@use 'rc-tooltip/assets/bootstrap';

.rc-tooltip {
  opacity: 1;
  font-size: unset;
  line-height: unset;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-inline-start-color: white;
  margin-inline-end: 4px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-inline-end-color: white;
  margin-inline-start: 4px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-block-end-color: white;
  margin-block-start: 4px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-block-start-color: white;
  margin-block-end: 4px;
}

.rc-tooltip-inner {
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  padding: 16px;
  color: unset;
}

.wrapperChildren {
  display: inline-block;
}
