@use './colors.scss';
@use './layers.scss';

.nv-spinner {
  display: flex;
  justify-content: center;
  z-index: layers.$layerPriority;
  margin: auto;

  &-size {
    &--x-small {
      inline-size: 50px;
    }

    &--small {
      inline-size: 100px;
    }

    &--medium {
      inline-size: 150px;
    }

    &--large {
      inline-size: 200px;
    }

    &--x-large {
      inline-size: 300px;
    }
  }

  &-fullscreen {
    align-items: center;
    background-color: rgba(colors.$nero, 0.5);
    display: flex;
    block-size: 100%;
    justify-content: center;
    inline-size: 100%;

    position: fixed;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
  }
}
