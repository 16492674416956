@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.categoryTitle {
  color: $gray2;
  font-size: 14px;
  font-weight: $font-weight-medium;
  margin-bottom: 15px;
}

.sideMenu {
  padding-top: 30px;
  height: min-content;
  // inline-table makes to fit children width
  display: inline-table;

  @include screen-medium {
    max-inline-size: 150px;
    min-inline-size: 150px;
    position: sticky;
    top: 150px;
    overflow-y: scroll;
    height: calc(100vh - 156px); // screen height minus tabs and nav heights
    margin-block-end: 150px;
  }

  @include screen-large {
    max-width: 180px;
  }
}

.menuTitle {
  &:hover {
    color: $deepTeal;
  }

  &:focus {
    color: black;
    font-weight: $font-weight-medium;
  }
  cursor: pointer;
}

.activeTitle {
  color: black;
  font-weight: $font-weight-medium;
}

.categorySideMenuWrapper {
  display: flex;
  flex-direction: column;

  @include screen-medium {
    flex-direction: row;
  }

  .innerChildWrapper {
    padding-top: 30px;

    @include screen-medium {
      padding-left: 25px;
      padding-top: 50px;
    }
    width: 100%;
  }
}

.categorySideMenu {
  padding-top: 30px;
}

.gridClass {
  grid-template-columns: 1fr;
}

.menuItem {
  margin-bottom: 15px;
}
