@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  padding: 30px 0 60px 0;
}

.content {
  max-width: 915px;
  padding-top: 30px;
}

.title {
  font-weight: $font-weight-demibold;
  font-size: 34px;
  line-height: 42px;
  margin-block-end: 30px;
}

.packageCard {
  box-shadow: 0 0 20px 0 rgba(40, 41, 44, 0.12);
  padding: 20px;
  margin-block-end: 30px;
  border-radius: 8px;

  @include screen-medium {
    padding: 30px;
  }
}

.packageCardTitle {
  font-weight: $font-weight-demibold;
  font-size: 22px;
  line-height: 30px;
  margin-block-end: 5px;
}

.imageRow {
  display: flex;
  border-block-end: 1px solid $gray5;
  padding-block-end: 20px;
}

.titleRowContainer {
  display: flex;
}

.imageContainerDesktop {
  margin-inline-start: auto;
  padding-inline-start: 30px;
  display: none;
  visibility: hidden;

  @include screen-medium {
    display: block;
    visibility: initial;
  }
}

.imageContainerMobile {
  margin-block-end: 10px;

  @include screen-medium {
    display: none;
    visibility: hidden;
  }
}

.imageWrapper {
  overflow: hidden;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
}

.packageCardTests {
  border-block-end: 1px solid $gray5;
  padding-block-end: 20px;
  margin-block-start: 20px;
  position: relative;
}

.addingPackages {
  padding-block-end: 0;
}

.checkboxContainer {
  padding-block-start: 3px;
}

.panelNameContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  a {
    font-weight: $font-weight-demibold;
    text-decoration: none;
    color: #000000;
  }
}

.geneCount {
  margin-inline-start: 20px;
  text-align: end;
}

.buttonContainer {
  padding-block-start: 20px;
  display: flex;

  @include screen-medium {
    justify-content: flex-end;
    padding-block-start: 30px;
  }
}

.optionalTestContainer {
  background-color: $gray7;
  margin: 0 -20px;
  padding: 10px 30px;

  @include screen-medium {
    margin: 0 -30px;
  }
}

.optionalTest {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-block-end: 10px;
  }
}

.optionalTestLabel {
  margin-inline-start: 10px;
  text-decoration: none;
  color: inherit;

  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

.isolatedCheckbox {
  font-weight: $font-weight-demibold;
}
