@use './colors.scss';
@use './font-weights.scss';

.nv-label {
  display: inline-flex;
  align-items: center;

  svg {
    margin-inline-start: 6px;
    block-size: 14px;
    inline-size: 14px;

    path {
      fill: colors.$gray2;
    }
  }
}

.nv-form-label {
  font-weight: font-weights.$font-weight-demibold;
  font-size: 12px;
  line-height: 14px;
  margin-block-end: 3px;
  letter-spacing: 0.5px;
  color: colors.$nero;
}
