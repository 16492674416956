@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/layers.scss';

.root {
  width: 100%;
  background: white;
  display: flex;

  @include screen-medium {
    width: 470px;
  }

  @include screen-extra-large {
    width: 570px;
  }
}

.tooltipGenesTitle {
  color: black;
  font-weight: $font-weight-medium;
  font-size: 14px;
}

.tooltipGenesDescription {
  color: black;
  font-size: 12px;
  text-transform: uppercase;

  p {
    display: inline;
  }
}

.closeIcon {
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;
  margin-inline-start: auto;

  &:hover {
    path {
      fill: black;
    }
  }
}

.unselectedPanel {
  p {
    color: $gray3;
  }
}

.unselectedGene {
  color: $gray3;
}

.addonList {
  max-height: 600px;
  overflow: auto;
}

.geneSection {
  &:not(:last-child) {
    padding-block-end: 20px;
  }
}

.edgeCard {
  @include screen-medium {
    //floating is used for the absolute positioned item in a relative container (not full screen)
    //used to customize the position of the wide absolute element when it is positioned near the edge
    /* stylelint-disable */
    float: right;
  }
}

.toolTipContainer {
  width: 100%;
}
