@use './colors.scss';

/* Typography organization within ids-sass:
   -----------------------------------------------------------------
   - The base font size is 16px – and is defined in typography.scss
   - Font sizes larger than 16 pixels are called headlines, headings, or subheadings – and are defined in headings.scss
   - Font sizes smaller than 14 pixels are called fine print – and are defined in fine-print.scss
*/

.nv-fine-print-1 {
  font-size: 14px;
  line-height: 18px;
}

.nv-fine-print-1-gray {
  font-size: 14px;
  line-height: 18px;
  color: colors.$gray1;
}

.nv-fine-print-2 {
  font-size: 12px;
  line-height: 18px;
}
