@use './breakpoints.scss';

/* CSS Grid-based grid system
   ----------------------------------------------------------------- */
.nv-grid {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(4, 1fr);

  @include breakpoints.screen-small-slim-only {
    grid-column-gap: 10px;
  }

  @include breakpoints.screen-medium {
    grid-column-gap: 30px;
    grid-template-columns: repeat(12, 30px);
  }

  @include breakpoints.screen-large {
    grid-template-columns: repeat(12, 55px);
  }

  @include breakpoints.screen-extra-large {
    grid-template-columns: repeat(12, 75px);
  }
}
