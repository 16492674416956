@use './breakpoints';
@use './colors';

.nv-pagination {
  margin-block-start: 60px;
  margin-block-end: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoints.screen-medium {
    flex-direction: row;
    justify-content: space-between;
  }

  &-page-length {
    flex-grow: 1;
  }

  &-navigator {
    flex-grow: 2;

    > span {
      margin-inline-start: auto;
    }
  }

  &-evenly-spaced-box {
    display: flex;
    margin-block-end: 10px;

    @include breakpoints.screen-medium {
      margin-block-end: 0;
    }

    &:first-child > span {
      margin-inline-end: auto;
    }

    &:not(:first-child):not(:last-child) {
      justify-content: center;
    }
  }

  &-clickable-icon {
    display: inline-block;
    cursor: pointer;
  }

  &-input {
    display: inline-block;
    inline-size: 60px;
    block-size: 40px;
    border: 1px solid colors.$gray3;
    border-radius: 3px;
    margin-block: 0;
    margin-inline: 10px;
    text-align: center;
  }
}
