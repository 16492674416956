@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.card {
  grid-column: span 4;
  width: 100%;
  min-height: 160px;
  height: fit-content;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  @include screen-medium {
    padding: 30px;
    grid-column: span 6;
  }

  @include screen-extra-large {
    padding: 30px;
    grid-column: span 4;
  }
}

.cardMain {
  display: flex;
  justify-content: space-between;
  position: unset;

  @include screen-large {
    position: relative;
  }
}

.cardMinMain {
  height: 120px;

  @include screen-medium {
    height: 100px;
  }
}

.titleRoot {
  text-decoration: none;

  h3 {
    color: black;
  }
}

.cardTitle {
  font-weight: $font-weight-medium;
  height: fit-content;
  max-width: 215px;
  margin-right: 10px;
  text-decoration: none;
  color: black;

  &:hover {
    color: $deepTeal;
  }
}

.cardGeneCount {
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  width: 100%;
  justify-content: flex-end;
}

.cardInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: unset;

  @include screen-large {
    position: relative;
  }
}

.addButton {
  margin-top: auto;
  align-self: flex-end;
}

.cardCheckbox {
  margin-top: 20px;
}

.cardCheckbox:first-child {
  margin-top: 40px;
}

.cardCheckboxList {
  display: flex;
  flex-direction: column;
}

.checkWrapper {
  margin-top: 20px;
  display: flex;
  font-weight: $font-weight-medium;

  svg {
    margin-right: 10px;
    min-width: 24px;

    path {
      fill: $deepTeal;
    }
  }
}

.firstItem {
  margin-top: 40px;
}

.cardConfirmation {
  margin-top: 30px;

  margin-bottom: 10px;
  width: 100%;

  @include screen-medium {
    margin-bottom: 0;
    margin-left: auto;
    width: fit-content;
  }
}

.tooltipWrapper {
  margin-top: 40px;
}

.panelAddonStyles {
  margin-top: 20px;
}

.skeletonWrapper {
  grid-column: span 4;
}
