@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/transition-durations.scss';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nv-modal-component-wrapper {
  block-size: calc(100% - 30px);
  overflow: hidden;
  max-block-size: unset;
  background: transparent;
  position: fixed;
  inset-block: 0;
  inset-inline: 0;
  margin: auto;
  inline-size: 100%;
  max-inline-size: 80%;
  z-index: $layerModal;
  border: none;
  animation-name: fadeIn;
  animation-fill-mode: backwards;
  transition: opacity $transition-duration-entering;
  animation-duration: $transition-duration-entering;
  border-radius: 4px;

  &::backdrop {
    background: rgba($nero, 0.5);
  }

  @include screen-medium {
    inline-size: fit-content;
    max-inline-size: 690px;
    min-inline-size: 375px;
    block-size: calc(100% - 100px);
  }
}

.nv-modal-content {
  background: $white;
  box-shadow:
    0 3px 3px -2px rgba(black, 0.2),
    0 3px 4px 0 rgba(black, 0.14),
    0 1px 8px 0 rgba(black, 0.12);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-block-size: 100%;
  border-radius: 4px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.nv-modal-close-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-inline-start: 10px;
  margin-block-start: 8px;
  align-self: start;

  svg path {
    fill: $gray3;
  }

  @include screen-medium {
    align-self: unset;
    margin-block-end: unset;
  }
}

.nv-modal-header-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  flex-shrink: 0;
  max-block-size: 100%;
  overflow: hidden;
  flex-direction: row;

  .nv-modal-header {
    font-weight: $font-weight-medium;
  }
}

.nv-modal-header-spacing {
  margin-block-start: 30px;
}

.nv-modal-body-wrapper {
  block-size: 100%;
  max-block-size: 70vh;
  overflow-y: auto;
  overscroll-behavior: contain;
  position: relative;
  flex: 1 1 auto;
}

.nv-modal-footer-spacing {
  margin-block-end: 30px;
}

.nv-modal-footer-wrapper {
  inline-size: 100%;
  display: flex;
  flex-direction: column;

  button {
    inline-size: 100%;

    &:not(:last-child) {
      margin: 0;
      margin-block-end: 10px;
    }
  }

  @include screen-medium {
    inline-size: unset;
    flex-direction: row;

    button {
      inline-size: unset;

      &:not(:last-child) {
        margin: 0;
        margin-inline-end: 30px;
      }
    }
  }
}

.nv-modal-reverse-buttons {
  flex-direction: column-reverse;

  button:not(:last-child) {
    margin: 0;
    margin-block-start: 10px;
  }

  @include screen-medium {
    flex-direction: row-reverse;

    button:not(:last-child) {
      margin: 0;
      margin-inline-start: 30px;
    }
  }
}

.nv-modal-type-information {
  .nv-modal-header {
    color: $neutral;
  }
  border-block-start: 4px solid $neutral;
}

.nv-modal-type-error {
  .nv-modal-header {
    color: $error;
  }
  border-block-start: 4px solid $error;
}

.nv-modal-buttons-container {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  flex-shrink: 0;
}

.nv-modal-body-spacing {
  padding: 0 30px;
}
