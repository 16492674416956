@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';
@import '@invitae/ids-sass/sass/transition-durations.scss';

.cardWrapper {
  grid-column: span 4;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(40, 41, 44, 0.12);
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $white;
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: none;
  }

  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  @include screen-medium {
    grid-column: span 12;
    flex-direction: row;
  }

  @include screen-large {
    flex-direction: column;
    width: 100%;
    max-width: 310px;
    grid-column: unset;
    position: relative;
    transform: translateY(0);
    transition: transform ease $transition-duration-entering;

    &:hover {
      transform: translateY(-10px);
    }
  }

  picture {
    img {
      width: 120px;
      max-height: 120px;
    }
  }

  @include ie-only {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;

  @include screen-medium {
    align-items: flex-start;
  }

  @include ie-only {
    width: 100%;
  }
}

.imageWrapper {
  padding-top: 45px;
  padding-bottom: 5px;

  @include screen-medium {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
  }

  @include screen-large {
    padding-top: 45px;
    padding-left: 0;
    padding-bottom: 5px;
  }
}

.cardHeaderAndText {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $nero;
  width: 100%;

  h3,
  h5 {
    width: 100%;

    @include screen-medium {
      text-align: left;
    }

    @include screen-large {
      text-align: center;
    }
  }

  span {
    @include screen-medium {
      text-align: left;
    }

    @include screen-large {
      text-align: center;
    }
  }

  @include screen-medium {
    align-items: flex-start;
  }

  @include screen-large {
    align-items: center;
  }
}

.miniCard.cardWrapper {
  flex-direction: column;
  grid-column: span 2;

  @include screen-medium {
    grid-column: span 4;
  }

  @include screen-large {
    grid-column: span 1;
  }

  picture {
    img {
      width: 80px;
      max-height: 80px;
    }
  }
}

.miniCard .card {
  padding: 5px 10px 20px;

  @include screen-medium {
    padding-top: 10px;
    padding-bottom: 25px;
  }
}

.miniCard .imageWrapper {
  padding: 0;
  padding-top: 10px;

  @include screen-medium {
    padding-top: 20px;
  }
}

.miniCard .heading {
  text-align: center;
  min-height: 35px;

  @include screen-small {
    font-weight: $font-weight-demibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.miniCard .cardText {
  display: none;
}
