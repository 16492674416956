/* stylelint-disable selector-class-pattern */
// Note - when importing CSS files, use `@use` instead of `@import` to guarantee rules are bundled into this file
@use 'react-datepicker/dist/react-datepicker';
@use './font-weights.scss';
@use './colors.scss';

.react-datepicker__header {
  background-color: colors.$white;
  border-block-end: none;
  padding: 0;
  padding-block-start: 16px;
}

.react-datepicker__navigation {
  inset-block-start: 8px;
}

.react-datepicker__navigation--previous {
  inset-inline-start: 8px;
}

.react-datepicker__navigation--next {
  inset-inline-end: 8px;
}

.react-datepicker__current-month,
.react-datepicker__day-names {
  margin-block-end: 16px;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-names {
  margin-inline: 6px;
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day-name {
  margin-inline: 9px;
}

.react-datepicker__day--in-selecting-range {
  background-color: colors.$polo;
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  background-color: colors.$ocean;
  border-radius: 50%;
}

div.react-datepicker__day--outside-month {
  background-color: transparent;
  color: transparent;
}

.react-datepicker__day--keyboard-selected {
  color: colors.$nero;
}

.react-datepicker__day--today {
  font-weight: font-weights.$font-weight-book;
}

.nv-calendar-day.react-datepicker__day--keyboard-selected {
  color: colors.$white;
}

/**
  react-datepicker uses `REM` to set fontSizes and some projects are having different sizes related to that
  so, we are fixing fontSizes here with `PX`
  start ->
**/
div.react-datepicker__day-name,
div.react-datepicker__day {
  font-size: 13px;
}

.react-datepicker__current-month {
  font-size: 15px;
}
/** <- end **/

/* stylelint-enable selector-class-pattern */
