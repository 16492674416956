/* stylelint-disable no-descending-specificity */

@use './breakpoints.scss';
@use './colors.scss';
@use './font-weights.scss';

$cell-padding: 6px 12px;

.nv-table {
  border: 1px solid colors.$gray4;

  &-component {
    inline-size: 100%;

    th,
    td {
      block-size: 100%;
      padding: $cell-padding;

      &:not(:last-child) {
        border-inline-end: 1px solid colors.$gray4;
      }

      &.nv-table-checkbox-container {
        inline-size: 50px;
        max-inline-size: 50px;
      }
    }

    thead {
      box-shadow: 0 1px 1px colors.$gray4;

      tr {
        background-color: colors.$gray7;
      }

      th {
        white-space: nowrap;

        &.nv-table--sortable {
          cursor: pointer;
        }

        .nv-table-caret-icon {
          margin-inline-start: 4px;
          display: inline-flex;
          align-items: center;
        }
      }

      .nv-table-search-input {
        font-weight: font-weights.$font-weight-book;
        max-block-size: 32px;
        min-block-size: 32px;
      }
    }

    .nv-table-label-wrapper {
      display: flex;
      align-items: center;
    }

    tbody {
      tr {
        background-color: colors.$white;

        &.nv-table-row-selected {
          background-color: colors.$gray7;
        }
      }
    }

    tr:not(:last-child) {
      border-block-end: 1px solid colors.$gray4;
    }
  }

  &-markdown {
    overflow: auto;
    max-block-size: 500px;

    table {
      position: relative;
      inline-size: 100%;
      block-size: 100%;

      thead {
        position: sticky;
        inset-block-start: 0;

        th {
          background: colors.$ocean;
          color: colors.$white;
          font-weight: font-weights.$font-weight-demibold;
          font-size: 12px;
        }
      }

      tbody {
        tr:nth-child(odd) {
          background: colors.$catskill;
        }
      }
    }

    @include breakpoints.screen-medium {
      max-block-size: 100%;
    }
  }
}
