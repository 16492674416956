@use './colors.scss';
@use './font-weights.scss';

$padding: 2px;
$pointerSize: 24px;

.nv-switch {
  display: flex;

  &-label {
    &[aria-disabled='true'] {
      pointer-events: none;
    }
  }

  &-switcher {
    min-inline-size: 55px;
    block-size: 30px;
    border-radius: 15px;
    position: relative;
    background: colors.$gray3;
    box-sizing: border-box;
    border: 1px solid colors.$gray1;
    margin-inline-end: 20px;
    cursor: pointer;
  }

  &-pointer {
    inline-size: $pointerSize;
    block-size: $pointerSize;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    inset-inline-start: $padding;
    inset-block: $padding;
    transition: all 200ms ease-in-out;

    border: 1px solid transparent;
    background: colors.$gray1;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin-block: -1px;
      margin-inline: -1px;
      border-radius: inherit;
      background: linear-gradient(180deg, rgba(black, 0.0001) 0%, rgba(black, 0.02) 79.22%, rgba(black, 0.04) 99.02%),
        linear-gradient(
          180deg,
          rgba(colors.$white, 0.300208) 0%,
          rgba(colors.$white, 0.06) 20%,
          rgba(colors.$white, 0.0001) 100%
        );
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      background: colors.$gray1;
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    block-size: 0;
    inline-size: 0;

    &:checked + .nv-switch-switcher {
      background: colors.$bermuda;
      border-color: colors.$deepTeal;

      .nv-switch-pointer {
        inset-inline-start: calc(100% - #{$pointerSize} - #{$padding});
        background: colors.$deepTeal;

        &::after {
          background: colors.$deepTeal;
        }
      }
    }

    &[aria-disabled='true'] {
      pointer-events: none;
    }

    &[aria-disabled='true'] + .nv-switch-switcher {
      background: colors.$gray6;
      border-color: colors.$gray3;
      cursor: not-allowed;

      .nv-switch-pointer {
        background: colors.$gray3;

        &::after {
          background: colors.$gray3;
        }
      }
    }

    &:focus + .nv-switch-switcher {
      outline: none;
      box-shadow:
        0 0 0 2px colors.$white,
        0 0 0 4px colors.$cooledCarbon;
    }
  }

  &-description {
    font-weight: font-weights.$font-weight-light;
    margin-block-start: 2px;
  }

  &-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-block-start: 3px;
  }

  &-helper-icon {
    display: inline-block;
    margin-inline-start: 10px;
    position: absolute;
    cursor: pointer;
  }
}
