/* Body Copy
   -----------------------------------------------------------------
   The body copy pattern is the main text in one section of the page. It consists of
   one or more paragraphs of text, and optionally lists, and basic formatting like bold and italics.
*/

/* Subsequent paragraphs of text spacing */
.nv-body p + p {
  margin-block-start: 10px;
}

/* When a paragraph comes after a list */
.nv-body ul + p,
.nv-body ol + p {
  margin-block-start: 10px;
}

/* Spacing before a bulleted list that is within body copy */
.nv-body .nv-bulleted-list,
.nv-body .nv-numbered-list {
  margin-block-start: 10px;
}
