/* About the page wrapper
   -----------------------------------------------------------------
  The page wrapper serves to define the largest screen size designed for, supported for, and tested for. Most of the
  content on a page will already be contained within a section container (nv-container) and never even be affected by
  the page wrapper. It’s a fallback for any other content, that is most likely designed to go full width, not already
  within a section container. The page wrapper is intended to prevent that full width content from looking too odd on
  really wide monitors. If a visitor has a really wide web browser window open, it's a gentle nudge to them that says,
  "hey, you may want to make your window smaller, because this site is not designed for that screen size."
*/

/* Maximum and minimum page dimensions
   -----------------------------------------------------------------
   - Minimum width of 320px — the smallest mobile screen size supported
   - Maximum width of 1990px — the largest size designed for as determined by the design team
   - Centered horizontally in the viewport using margin: auto
*/

/* Integration options
   -----------------------------------------------------------------
   1. For React apps, add the class to the “root” DOM node in index.html. EG: <div class="nv-page" id="root"></div>
   2. For Next.js apps, add a wrapper div around the <Main /> element in _document.tsx. EG: <div className="nv-page"><Main /></div>
*/

.nv-page {
  min-inline-size: 320px;
  max-inline-size: 1990px;
  margin-inline: auto;
}
