@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.panelItemRoot {
  border: 1px solid $gray4;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 4px;
}

.lastPanelItem {
  margin-block-end: 150px;
}

.panelMainContainer {
  display: flex;
  padding: 0 20px;
  position: relative;

  @include screen-large {
    padding: 0 30px;
  }
}

.panelTextWrapper {
  display: flex;
  flex-direction: column;

  @include screen-medium {
    flex-direction: column;
  }

  @include screen-large {
    flex-direction: row;
  }
}

.panelImageWrapper {
  min-width: 80px;
  min-height: 80px;
  max-height: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  @include screen-large {
    min-width: 140px;
    min-height: 140px;
    max-height: 140px;
    max-width: 140px;
    margin: 30px 0;
  }
}

.cardGeneCount {
  margin-left: auto;
  padding-top: 30px;
  height: min-content;
  text-align: right;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.cardGeneCountInnerContent {
  display: flex;
  text-align: left;
}

.panelTextContainer {
  display: flex;
  flex-direction: column;

  padding: 0 10px 20px 0;

  @include screen-large {
    max-width: 668px;
    padding: 30px;
  }
}

.panelTitle {
  margin-top: 20px;

  @include screen-medium {
    margin-top: 0;
  }
}

.panelImageTitle {
  display: none;
  padding-left: 20px;
  max-width: 265px;

  @include screen-medium {
    display: flex;
  }

  @include screen-large {
    display: none;
  }
}

.panelRegularTitle {
  display: block;

  @include screen-medium {
    display: none;
  }

  @include screen-large {
    display: block;
    padding-right: 85px;
  }
}

.panelImageTitleWrapper {
  display: flex;
  margin-top: 20px;

  @include screen-large {
    margin-top: 0;
  }
}

.panelDescription {
  max-height: 72px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  //disabled styleling check because stylelint errors that it cannot find a style with this name
  //this rule enables ellipsis at the end of 3 lines (works in Chrome/Safari)
  /* stylelint-disable */
  -webkit-box-orient: vertical;
}

.panelAdditionalData {
  margin: 10px 0;
  color: $gray1;
  display: flex;
  align-items: center;

  button {
    margin-left: 5px;
    margin-top: 5px;
    @include screen-large {
      margin-top: 0px;
    }
  }
}

.cardImage {
  width: 100%;
  height: auto;
}

.panelAddonWrapper {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow-y: hidden;

  transition-delay: 0s;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  transition-property: max-height;

  & > * {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }

  &.addonOpened {
    border-top: 1px solid $gray4;
    max-height: 400px;
    overflow-y: scroll;
    transition-delay: 0s;
    transition-timing-function: ease-out;
    transition-duration: 0.5s;
    transition-property: max-height;

    & > * {
      opacity: 1;
    }
  }
}

.addonInfoWrapper {
  display: flex;
  align-items: flex-start;

  padding: 2px 20px;
  background: $gray7;
  cursor: default;
  @include screen-large {
    align-items: center;
    padding: 2px 30px;
  }
}

.addonInfoIcon {
  background: $gray4;
  width: 27px;
  height: 1px;
  margin-top: 12px;
  @include screen-large {
    margin-top: 0px;
  }
}

.addonInfoData {
  display: flex;
  flex-direction: column;
  @include screen-large {
    flex-direction: row;
  }
}

.addonInfoTitle {
  font-size: 14px;
  color: $gray2;
  margin-left: 10px;
  font-weight: 500;
}

.addonInfoDescription {
  font-size: 12px;
  color: $gray2;
  margin-left: 10px;
  @include screen-large {
    margin-left: 20px;
  }
}

.panelBottomWrapper {
  border-top: 1px solid $gray4;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  flex-direction: column;

  flex-direction: column;
  @include screen-medium {
    flex-direction: row-reverse;
  }
  @include screen-large {
    padding-left: 30px;
  }
  @include screen-extra-large {
    padding: 20px 30px;
  }
}

.panelB {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include screen-medium {
    width: min-content;
    flex-direction: row;
  }
}

.bottomCancelButton {
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
  justify-content: center;
  @include screen-medium {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.cancelButton {
  color: $gray1;
  text-decoration: underline;
  cursor: pointer;
}

.panelDetailButton {
  width: 100%;
  margin-bottom: 20px;

  @include screen-medium {
    width: initial;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.panelAddButton {
  width: 100%;

  @include screen-medium {
    width: initial;
  }
}

.tooltipWrapper {
  position: absolute;
  right: 0;
  margin-right: 20px;

  @include screen-large {
    margin-right: 30px;
  }
}

.tooltipModalWrapper > div {
  margin-top: 60px;

  @include screen-small {
    width: calc(100% - 40px);
    left: 20px;
  }
  @include screen-medium {
    padding-right: 20px;
  }
  @include screen-large {
    padding-right: 30px;
  }
}

.geneIcon {
  margin-top: 3px;
  margin-right: 5px;
}

//colors used for the background variation logic in panel items list
// 6 colors in total

.panelColor0 {
  background: #81b3af;
}

.panelColor1 {
  background: #99ba9d;
}

.panelColor2 {
  background: #c0cea8;
}

.panelColor3 {
  background: #9ccfe0;
}

.panelColor4 {
  background: #406383;
}

.panelColor5 {
  background: #745c77;
}

.sponsoredTag {
  display: inline;
  margin-left: 10px;
  vertical-align: middle;
}
