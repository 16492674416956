@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.nv-checkbox-input-wrapper {
  display: flex;
  align-items: flex-start;
}

.nv-checkbox-wrapper {
  &:not(:last-child) {
    margin-block-end: 10px;
  }

  &--inline {
    .nv-checkbox-input-wrapper {
      display: inline-flex;
    }

    &:not(:last-child) {
      margin-inline-end: 10px;
    }
  }

  &--block {
    .nv-checkbox-input-wrapper {
      inline-size: 100%;
    }
  }
}

.nv-checkbox-content {
  position: relative;
  margin-inline-end: 10px;
  display: flex;
}

.nv-checkbox {
  position: relative;
  block-size: 24px;
  min-inline-size: 24px;
  background-color: $white;
  transition: background-color 0.1s ease-in;
  border: $cooledCarbon solid 1px;
  border-radius: 2px;
  cursor: pointer;

  &--error {
    border-color: $error;
  }

  + .nv-checkbox-check {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    margin-block: auto;
    margin-inline: auto;
    inline-size: 100%;
    block-size: 100%;
    border-radius: 2px;
    pointer-events: none;
    border: 1px solid transparent;
  }

  &:focus {
    outline: none;

    + .nv-checkbox-check {
      outline: $cooledCarbon 2px auto;
      outline-offset: 4px;
    }
  }

  // checkbox hover style
  &:hover & {
    background-color: $gray5;
  }

  // custom checkmark
  &::after {
    content: '';
    position: absolute;
    display: block;
    background-color: $white;
    border-radius: 10px / 7px;
    inset-inline-start: 1px;
    inset-block-end: 11px;
    inline-size: 0;
    block-size: 3px;
    transform-origin: 0 100%;
    transform: rotate(45deg);
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    background-color: $white;
    border-radius: 7px / 10px;
    inset-inline-start: 6px;
    inset-block-end: 6px;
    inline-size: 3px;
    block-size: 0;
    transform-origin: 0 100%;
    transform: rotate(45deg);
  }

  // checkbox checked styles
  &[aria-checked='true'] {
    background-color: $deepTeal;
    border-color: $deepTeal;

    &::after {
      inline-size: 10px;

      @media (prefers-reduced-motion: no-preference) {
        transition: width 0.1s ease-in;
      }
    }

    &::before {
      block-size: 18px;

      @media (prefers-reduced-motion: no-preference) {
        transition: height 0.1s ease-in 0.1s;
      }
    }
  }

  // checkbox checked styles
  &[aria-checked='mixed'] {
    background-color: $deepTeal;
    border-color: $deepTeal;

    // checkbox mixed style
    &::after {
      inline-size: 18px;
      transform: rotate(0deg);
      inset-inline-start: 2px;
      inset-block-end: 10px;
    }
  }

  // disabled styles
  &[aria-disabled='true'] {
    background-color: $gray3;
    border-color: $gray3;
    cursor: not-allowed;
  }

  &.nv-checkbox-tick-mark {
    background-color: $white;
    border-color: $white;
    cursor: default;

    + .nv-checkbox-check {
      outline: none;
    }

    &::before,
    &::after {
      background-color: $deepTeal;
    }

    &[aria-disabled='true'] {
      cursor: default;

      &::before,
      &::after {
        background-color: $gray3;
      }
    }
  }
}

// size variations

/* stylelint-disable no-descending-specificity */
.nv-checkbox-size--small {
  .nv-checkbox-content {
    margin-block-start: 3px;
  }

  .nv-checkbox {
    block-size: 18px;
    min-inline-size: 18px;

    &::after {
      inset-inline-start: 1px;
      inset-block-end: 8px;
      block-size: 2px;
    }

    &::before {
      inset-inline-start: 5px;
      inset-block-end: 5px;
    }

    &[aria-checked='true'] {
      &::after {
        inline-size: 7px;
      }

      &::before {
        block-size: 12px;
        inline-size: 2px;
      }
    }

    &[aria-checked='mixed'] {
      &::after {
        inline-size: 12px;
        inset-inline-start: 2px;
        inset-block-end: 7px;
      }
    }
  }
}

.nv-checkbox-legend {
  font-weight: $font-weight-book;
  margin-block-start: 5px;
  display: block;
  color: $gray3;

  &.errorColor {
    color: $error;
  }
}

.nv-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  inline-size: 100%;
  align-items: flex-start;
  min-block-size: 46px;
  padding-block-start: 11px;
}

.nv-form-control--error {
  .nv-checkbox[aria-checked='false'] {
    border-color: $error;
  }
}
