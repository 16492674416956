@use './colors.scss';
@use './layers.scss';
@use './breakpoints.scss';
@use './font-weights.scss';

.nv-tabs {
  &-content {
    padding-block-start: 10px;
  }

  &-header {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: layers.$layerPriority;
      inset: 0;
      background: colors.$white;
    }
  }

  &-text-content {
    padding-block-end: 20px;

    @include breakpoints.screen-large {
      padding-block-end: 40px;
    }

    .textCenter {
      text-align: center;
    }

    .textLeft {
      text-align: start;
    }

    .textRight {
      text-align: end;
    }

    .heading {
      margin-block-end: 20px;
    }

    .subheading {
      margin-block-end: 10px;
    }

    *:last-child {
      margin-block-end: 0;
    }
  }

  &-header-std {
    .tab-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      position: relative;
    }

    .tab-header-root {
      display: flex;
      flex-direction: row;
      border-block-start: 1px colors.$gray5 solid;
      border-block-end: 1px colors.$gray5 solid;
      inline-size: 100%;
      overflow: auto;
      white-space: nowrap;
      // hide horizontal scrollbar on firefox
      scrollbar-width: none;
      -ms-overflow-style: none; /* IE */

      &::-webkit-scrollbar {
        display: none; /* Chrome and Safari */
      }
    }

    .hidden {
      display: none;
    }

    .tab {
      padding-block: 24px 20px;
      padding-inline: 20px;
      border-block-end: 4px colors.$white solid;
      cursor: pointer;

      &:focus {
        outline: none;
        color: colors.$deepTeal;
        border-block-end: 4px colors.$deepTeal solid;
      }

      &:hover {
        color: colors.$deepTeal;
      }

      @include breakpoints.screen-medium {
        padding-block: 24px 20px;
        padding-inline: 40px;
      }

      &[aria-disabled='true'] {
        color: colors.$gray3;
        cursor: not-allowed;

        &:hover {
          color: colors.$gray3;
        }

        &.selected,
        &:focus {
          color: colors.$gray3;
          border-block-end: 4px colors.$gray3 solid;
        }
      }
    }

    .selected {
      color: colors.$deepTeal;
      border-block-end: 4px colors.$deepTeal solid;
    }

    .tab-text {
      font-weight: font-weights.$font-weight-demibold;
    }

    .arrow {
      cursor: pointer;
    }

    .button-left,
    .button-right {
      inset-block: 0;
      position: absolute;
      background: colors.$white;
      block-size: 24px;
      inline-size: 24px;
      margin: auto;
    }

    .button-right {
      inset-inline-end: 0;
    }

    .button-left {
      inset-inline-start: 0;
    }
  }

  &-header-dropdown {
    $inputHeight: 46px;
    $iconSize: 24px;
    $boxShadow: 0 2px 8px rgb(0 0 0 / 15%);
    $paddingInlineItems: 10px;
    $borderInlineStartItem: 4px;

    .wrapper {
      position: relative;
    }

    .wrapper-open {
      box-shadow: $boxShadow;

      &::before {
        content: '';
        position: fixed;
        inset: 0;
        z-index: layers.$layerPriority;
      }
    }

    .input-wrapper {
      inline-size: 100%;
      block-size: $inputHeight;
      padding-inline-start: $paddingInlineItems + $borderInlineStartItem;
      padding-inline-end: $paddingInlineItems;
      background: colors.$white;
      display: flex;
      align-items: center;
      border: 1px solid colors.$gray5;
      border-inline-start: none;
      border-inline-end: none;
      cursor: pointer;
      position: relative;
      z-index: layers.$layerPriority;
      user-select: none;
    }

    .input-div {
      inline-size: 100%;
      user-select: none;
      color: colors.$deepTeal;
      padding-inline-end: $iconSize + $paddingInlineItems;

      &[aria-disabled='true'] {
        color: colors.$gray3;
      }
    }

    .input {
      outline: none;
      pointer-events: none;
      display: none;
    }

    .icon {
      position: absolute;
      inset-inline-end: $paddingInlineItems;
    }

    .dropdown-wrapper {
      position: absolute;
      inset-block-start: $inputHeight;
      inset-inline: auto;
      background: colors.$white;
      inline-size: 100%;
      z-index: layers.$layerMenu;
      display: block;
      overflow: auto;
      max-block-size: $inputHeight * 5;
      box-shadow: $boxShadow;
    }

    .dropdown-item {
      block-size: $inputHeight;
      border-inline-start: $borderInlineStartItem solid transparent;
      padding-inline-start: $paddingInlineItems;
      padding-inline-end: $paddingInlineItems;
    }

    .dropdown-item-button {
      inline-size: 100%;
      block-size: 100%;
      text-align: start;
      cursor: pointer;
      color: colors.$gray1;

      &[aria-disabled='true'] {
        color: colors.$gray3;
      }
    }

    .item-selected {
      border-inline-start-color: colors.$deepTeal;

      .dropdown-item-button {
        color: colors.$deepTeal;
      }

      &.disabled {
        border-inline-start-color: colors.$gray3;

        .dropdown-item-button {
          color: colors.$gray3;
        }
      }
    }
  }
}
