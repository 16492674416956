@use './layers.scss';

.nv-cursor-default {
  cursor: default;
}

.nv-cursor-pointer {
  cursor: pointer;
}

.maxIconSize svg {
  max-inline-size: 24px;
  max-block-size: 24px;
}

:root {
  --zIndexNvSelect: #{layers.$layerModal};
}
