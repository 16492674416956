@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.nv-tooltip-limitedWidth {
  max-inline-size: 335px;
}

.nv-tooltip-bodyText {
  font-size: 14px;
  line-height: 18px;
  color: $nero;
}

.nv-tooltip-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  + .nv-tooltip-bodyText {
    margin-block-start: 4px;
  }
}

.nv-tooltip-title {
  font-weight: $font-weight-demibold;
  font-size: 16px;
  line-height: 20px;
  color: $nero;
}

.nv-tooltip-closeIcon {
  block-size: 14px;
  inline-size: 14px;
  margin-inline-start: 14px;
  cursor: pointer;

  path {
    fill: $gray3;
  }
}

.nv-tooltip-text {
  font-weight: $font-weight-book;
}

.nv-tooltip-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 8px;

  button {
    padding-inline: 0;

    + button {
      margin-inline-start: 24px;
    }
  }
}

.nv-tooltip-toRight {
  justify-content: right;
}
