@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.breadcrumbsWrapper {
  padding: 10px 0;

  &:not(.breadcrumbsGray) .breadcrumbs {
    @include screen-small {
      margin: 0;
    }
  }

  .breadcrumbs {
    .iconArrow {
      display: inline;
      margin: 0 10px;
    }

    .activeBreadcrumb > button {
      color: $eden;
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.breadcrumbsGray {
  background-color: $gray6;
}
