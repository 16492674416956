@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.GCSTAntiAtomRoot {
  // Includes the 150 px min-width from the category side menu + 25 px padding-left of the innerChildwrapper
  padding-inline-start: 175px;
  margin-block-end: 100px;

  @include screen-small {
    padding-inline-start: 0;
  }
}
