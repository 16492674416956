@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $gray7;

  .shapesWrapper {
    background-color: white;
    position: absolute;
    width: 100%;

    @include screen-small {
      left: 0;
    }
  }

  .shape {
    margin-block-start: 129px;
    width: 375px;
    height: auto;

    @include screen-medium {
      margin-block-start: 192px;
      width: 566px;
    }

    @include screen-large {
      margin-block-start: 220px;
      width: 614px;
    }
  }

  .header {
    text-align: center;
    font-weight: $font-weight-demibold;

    z-index: $layerPriority;

    height: 84px;
    margin-block: 20px 30px;
    width: 345px;
    font-size: 34px;
    line-height: 42px;

    @include screen-medium {
      width: 689px;
      height: 170px;
      font-size: 64px;
      line-height: 80px;
      margin-block-start: 50px;
      margin-block-end: 30px;
    }

    @include screen-large {
      height: 110px;
      width: 100%;
      margin-block-end: 0;
    }
  }

  .searchWrapper {
    width: 100%;

    padding-left: 15px;
    padding-right: 15px;

    z-index: $layerMenu;
    max-width: 743px; /* matches the width of the text in the page heading */

    h3 {
      display: none;
    }

    @include screen-large {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .search {
    width: 100%;
    height: 50px;

    @include screen-medium {
      height: 60px;
    }

    @include screen-extra-large {
      width: 743px;
    }
  }

  .searchRoot {
    padding: 0;
    height: 48px;

    // make the field match the width of the heading
    .navigation-search--field-wrapper {
      max-inline-size: 100%;
    }

    h3 {
      display: none;
    }

    :global(.test-catalog-search--popup) {
      @include screen-medium {
        max-height: unset;
      }
    }
  }

  .cardsWrapper {
    margin-block-start: 33px;
    display: flex;
    /* put the bottom space for the section here, to retain the box-shadow on cards when horizontally scrolling on small screens */
    padding-block-end: 20px;

    @include screen-extra-large {
      margin-block-start: 50px;
    }

    // horizontal scroll the cards on small screens
    @media (max-width: 914px) {
      align-self: flex-start;

      width: 100%;
      overflow-x: scroll;
    }
  }

  .cardsInnerWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    width: auto;
    padding-left: 15px;
    padding-right: 15px;

    @include screen-small {
      gap: 15px;
    }
  }
}

.cards {
  display: flex;
  gap: 15px;

  margin-block-start: 33px;

  @include screen-medium {
    gap: 30px;
  }

  /* put the bottom space for the section here, to retain the box-shadow on cards when horizontally scrolling on small screens */
  padding-block-end: 20px;

  @include screen-extra-large {
    margin-block-start: 50px;
  }

  // horizontal scroll the cards on small screens
  @media (max-width: 900px) {
    align-self: flex-start;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    width: 100%;
    scroll-padding: 0 15px 0 15px;
    padding-inline: 15px;
  }

  li {
    scroll-snap-align: start;
  }
}
