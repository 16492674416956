@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.nv-textarea-input {
  border: 1px solid $gray3;
  border-radius: 3px;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  margin-block-start: 2px;
  min-block-size: 120px;
  padding-inline-start: 10px;
  padding-block-start: 10px;
  resize: vertical;
  inline-size: 100%;
  background-color: $white;

  &:focus {
    outline: $cooledCarbon 2px auto;
  }

  &[aria-disabled='true'] {
    background-color: $gray6;
    color: $gray2;
    outline: none;
    cursor: not-allowed;
  }

  &:read-only {
    border: 1px solid $gray5;
  }
}

.nv-form-control--error {
  .nv-textarea-input {
    border: 1px solid $error;
    border-radius: 3px;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    margin-block-start: 2px;
    min-block-size: 120px;
    padding-inline-start: 10px;
    padding-block-start: 10px;
    resize: vertical;
    inline-size: 100%;
    background-color: $white;
  }
}
