@import '@invitae/ids-sass/sass/colors.scss';

/**
 * The classes .error, .info, .success and .warning are shared between NvUserNotification and NvToastNotification.
 * These components will likely be merged into one in the future.
 */

.nv-notification {
  display: inline-block;

  &-close-icon {
    cursor: pointer;
  }

  &--error {
    background-color: $errorBackground;
    border: 1px solid $error;
  }

  &--info {
    background-color: $neutralBackground;
    border: 1px solid $neutral;
  }

  &--success {
    background-color: $positiveBackground;
    border: 1px solid $positive;
  }

  &--warning {
    background-color: $warningBackground;
    border: 1px solid $warning;
  }

  &-text-only {
    background-color: transparent;
    border: none;
  }

  &-wrapper {
    border-radius: 5px;
    display: flex;
    gap: 20px;
    padding-block: 20px;
    padding-inline: 20px;
  }
}
