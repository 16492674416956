/* stylelint-disable no-descending-specificity */

@use './colors.scss';
@use './font-weights.scss';

@mixin input-styles {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    @content;
  }
}

.nv-form-control {
  &-content {
    display: flex;
    min-block-size: 46px;
    inline-size: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  .nv-form-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 3px;
    font-family: inherit;
    font-size: 16px;
    line-height: 18px;
    min-block-size: 46px;
    inline-size: 100%;
    padding: 10px;
    border: 1px solid colors.$gray3;
    background-color: colors.$white;

    @include input-styles {
      block-size: 100%;
      outline: none;
      inline-size: 100%;
    }

    &:focus-within {
      outline: colors.$cooledCarbon 2px auto;
    }
  }

  .nv-form-input-prefix {
    margin-inline-end: 10px;
    cursor: pointer;
  }

  .nv-form-input {
    block-size: 100%;
    outline: none;
    inline-size: 100%;
  }

  .nv-form-input-suffix {
    margin-inline-start: 10px;
    cursor: pointer;
  }

  .nv-form-input-non-click {
    pointer-events: none;
  }

  .nv-form-legend {
    font-weight: font-weights.$font-weight-book;
    margin-block-start: 3px;
    display: block;
    color: colors.$gray3;
  }

  &--error {
    .nv-form-input-wrapper {
      border: 1px solid colors.$error;

      :invalid {
        border: 1px solid colors.$gray3;
      }
    }

    .nv-form-legend {
      color: colors.$error;
    }
  }

  &--disabled {
    .nv-form-input-wrapper {
      background-color: colors.$gray6;
      color: colors.$gray2;
      outline: none;
    }
  }

  &--readonly {
    .nv-form-input-wrapper {
      border: 1px solid colors.$gray5;
    }
  }
}
