@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.whiteBackground {
  background-color: white;
}

.greyBackground {
  background-color: $gray7;
}

.panelCardsBreadcrumbs > div {
  padding-top: 30px;
}

/**
* section styles
*/

.root {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  max-width: 700px;

  @include screen-medium {
    padding-top: 60px;
    max-width: 1020px;
  }

  @include screen-extra-large {
    max-width: 1230px;
  }

  &:global(.no-padding) {
    padding-top: 10px;
  }
}

.sectionTitle {
  font-weight: $font-weight-medium;
  font-size: 28px;
  line-height: 34px;
  grid-column: span 4;

  @include screen-medium {
    font-size: 34px;
    line-height: 42px;

    grid-column: span 12;
  }

  @include screen-extra-large {
    grid-column: span 12;
  }
}

.nvSpinnerHidden {
  display: none;
}

.componentHidden {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.subHeading {
  margin-bottom: 20px;
}

.subtext {
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
  }
}
