@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  position: relative;
  width: 500px;

  @include screen-small {
    max-width: 100%;
  }
}

.fullWidth {
  width: 100%;
}

.input {
  margin: 0;
  width: 100%;
  border: 1px solid $gray3;
  border-radius: 3px;
  font-size: 16px;
  height: 46px;
  line-height: 18px;
  padding-left: 10px;
}

.rounded {
  border-radius: 20px;
}

.iconSearchWrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 12px;
}

.iconClearWrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 12px;
  cursor: pointer;
  border: none;
}
