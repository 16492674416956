@use './colors.scss';
@use './layers.scss';

.nv-contextual-menu {
  display: none;
  min-inline-size: 110px;
  max-inline-size: 280px;
  border-radius: 3px;
  box-shadow: 0 4px 4px colors.$gray5;
  border: 1px solid colors.$gray4;
  background: colors.$white;
  z-index: layers.$layerModal;

  &--show {
    display: flex;
    flex-direction: column;
  }

  &-toggle {
    display: inline-flex;
  }

  &-list {
    padding-block: 10px;
    margin: 0;

    &:not(:last-child) {
      border-block-end: 1px solid colors.$gray5;
    }
  }

  &-item {
    &-button {
      padding: 10px 20px;
      display: inline-flex;
      align-items: center;
      white-space: break-spaces;
      inline-size: 100%;
      cursor: pointer;
      color: colors.$nero;
      text-align: start;
      font-size: 16px;
      line-height: 24px;

      &:hover {
        background: colors.$gray7;
      }
    }

    &-icon {
      margin-inline-end: 15px;
      inline-size: 20px;
      block-size: 20px;
      min-inline-size: 20px;
      min-block-size: 20px;
      padding: 0;
      display: inline-flex;
      align-items: center;

      > * {
        max-inline-size: 100%;
        max-block-size: 100%;
        inline-size: auto;
        block-size: auto;
      }
    }

    &--disabled {
      button.nv-contextual-menu-item-button {
        color: colors.$gray2;
        cursor: not-allowed;
      }

      .nv-contextual-menu-item-icon {
        svg,
        path {
          fill: colors.$gray2;
        }
      }
    }
  }
}
