@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.nv-calendar {
  font-family: 'FuturaAllele', sans-serif;
  font-weight: $font-weight-book;
  font-size: 12px;
  line-height: 20px;
  color: $nero;
  border: 1px solid #a8aab3;

  div.nv-calendar-day {
    block-size: 30px;
    inline-size: 30px;
    padding-block-start: 1px;
    padding-inline-end: 1px;
    margin-inline: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-radius: 50%;
    }
  }
}

.nv-calendar-input-wrapper {
  position: relative;

  $minRangeWith: 230px;

  &.nv-calendar-input-range {
    input[type='text'] {
      min-inline-size: $minRangeWith;
    }
  }
}
