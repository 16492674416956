@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.geneRoot {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
  border-bottom: 1px solid $gray4;

  a {
    text-decoration: none;
    font-weight: $font-weight-medium;
  }

  @include screen-small {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  @include screen-medium {
    flex-direction: column;
    align-self: flex-start;
    padding: 20px 0;
  }

  @include screen-large {
    flex-direction: row;
  }

  .name {
    margin-right: 105px;
    font-size: 22px;
    font-weight: $font-weight-medium;

    @include screen-small {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    @include screen-medium {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }

    @include screen-large {
      margin-bottom: 0;
      flex-shrink: 2;
      min-width: 150px;
    }

    @include screen-extra-large {
      min-width: 200px;
    }
  }

  .alias {
    @include screen-small {
      margin-bottom: 7px;
    }

    @include screen-medium {
      width: 100%;
      padding: 0;
      align-self: flex-start;
    }

    @include screen-large {
      margin-top: 5px;
      margin-right: 30px;
      height: 100%;
      flex-shrink: 1;
    }
  }

  .seeMoreButton {
    margin-right: 30px;

    @include screen-small {
      width: 100%;
      margin: 13px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
    }
  }

  .rightContainer {
    @include screen-small {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-content: center;
      padding: 0;
    }

    @include screen-medium {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      align-self: flex-end;
    }

    @include screen-large {
      margin-top: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-left: 30px;
      align-self: center;
    }
  }

  .addButton {
    min-width: 162px;

    @include screen-small {
      margin: 7px 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
