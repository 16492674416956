@import '@invitae/ids-sass/sass/breakpoints.scss';

.cardGrid {
  grid-row-gap: 30px;
  max-width: unset;
}

.cardNarrowGrid {
  @include screen-large {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cardWideGrid {
  @include screen-large {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cardTwoItemsGrid {
  @include screen-large {
    grid-template-columns: repeat(3, 1fr);
  }

  div:first-child {
    @include screen-large {
      justify-self: end;
    }
  }

  div:not(:first-child) {
    @include screen-large {
      justify-self: start;
    }
  }
}

.cardOneItemsGrid {
  @include screen-large {
    grid-template-columns: repeat(1, 1fr);
  }
}
