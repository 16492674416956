@use './colors.scss';

a,
a:visited {
  color: colors.$ocean;
  text-decoration: underline;
}

a:hover,
a:active {
  color: colors.$cyprus;
}
