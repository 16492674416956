.nv-icon {
  &-wrapper {
    display: inline-block;
  }

  &-tab-chevron {
    box-shadow: 0 0 14px 8px white;
  }

  &--left {
    transform: rotate(90deg);
    display: inline-block;
  }

  &--up,
  &-tab-chevron--left {
    transform: rotate(180deg);
    display: inline-block;
  }

  &--right {
    transform: rotate(270deg);
    display: inline-block;
  }
}
