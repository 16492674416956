@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/layers.scss';

.categoryTabSectionRoot {
  //when there is a tall menu and 1 item, we need to make sure it doesn't look weird
  min-height: 100vh;

  //fist div of the container should be the tab navigation wrapper tabHeaderWrapper
  > div:first-child {
    background: white;
    position: sticky;
    top: 79px;
    z-index: $layerPriority;
  }

  nav > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    width: 340px;

    @include screen-medium {
      width: 690px;
      max-width: 690px;
    }

    @include screen-large {
      margin-left: auto;
      margin-right: auto;
      max-width: 990px;
      width: 990px;
    }

    @include screen-extra-large {
      max-width: 1230px;
      width: 1230px;
    }
  }
}
