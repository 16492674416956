@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  .genesWrapper {
    margin-top: 10px;
    margin-block-end: 150px;
  }

  .title {
    font-size: 18px;
    font-weight: $font-weight-book;
  }

  .geneSearchInput {
    margin: 20px 0 37px 0;
  }
}
