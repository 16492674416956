@import './colors.scss';

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@mixin spinnerColor($color) {
  border-color: $color;
  border-inline-start-color: transparent;

  &::after {
    border-color: $color;
  }
}

@mixin spinnerSize($size) {
  inline-size: $size;
  block-size: $size;
}

.nv-spinner-circle {
  display: inline-flex;
  border: 2px solid $deepTeal;
  border-radius: 50%;
  position: relative;
  animation: rotating 0.6s linear infinite;
  border-color: $deepTeal;
  border-inline-start-color: transparent;

  @include spinnerSize(24px);

  &::after {
    content: '';
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    inset-inline: -2px 0;
    inset-block: 0;
    margin: auto;
    border: 2px solid $deepTeal;
    display: block;
    opacity: 0.3;
    border-radius: 50%;
    box-sizing: content-box;
  }

  &--disabled,
  &--light,
  &--secondaryLight {
    @include spinnerColor($white);
  }

  &--secondaryLight.nv-spinner-circle--disabled,
  &--secondary.nv-spinner-circle--disabled {
    @include spinnerColor($gray3);
  }

  &--dark {
    @include spinnerColor($nero);

    &.nv-spinner-circle--disabled {
      @include spinnerColor($white);
    }
  }

  &--medium {
    @include spinnerSize(20px);
  }

  &--small {
    @include spinnerSize(18px);
  }
}
