@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.cardWrapper {
  width: 100%;
  height: 100%;
  border-radius: 8px;

  box-shadow: 0 0 20px rgba(40, 41, 44, 0.12);
  overflow: hidden;
  max-width: 255px;
  min-width: 255px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  transform: translateZ(0); // fix cut border-radius on mobile safari

  background-color: white;
  color: $nero;
  cursor: pointer;

  @include screen-medium {
    max-width: 270px;
    min-width: 270px;
  }

  @include screen-large {
    max-width: 310px;
    min-width: 310px;
  }

  @include screen-extra-large {
    min-width: 390px;
    max-width: 390px;
  }
}

.cardImageWrapper {
  height: 120px;
  position: relative;
  overflow: hidden;

  @include screen-large {
    height: 182px;
  }

  @include screen-extra-large {
    height: 220px;
  }
}

.cardImageBackgroundFirst {
  background-color: #81b3af;
}

.cardImageBackgroundSecond {
  background-color: #99a686;
}

.cardImageBackgroundThird {
  background-color: #b79f75;
}

.cardIcon {
  margin-inline-start: 20px;
  margin-block-start: 20px;
  position: absolute;

  @include screen-large {
    margin-inline-start: 30px;
    margin-block-start: 30px;
  }
}

.cardImage {
  height: 165px;
  width: 100%;

  @include screen-large {
    height: 300px;
  }
}

.cardImagePositionFirst {
  object-position: 50px 60%;
  width: 255px;
  object-fit: cover;

  @include screen-medium {
    object-position: 35px 60%;
    width: 270px;
  }

  @include screen-large {
    object-position: -18px -27px;
    width: 400px;
  }

  @include screen-extra-large {
    object-position: 15px -26px;
    width: 422px;
  }
}

.cardImagePositionSecond {
  height: 100%;
  object-fit: cover;
}

.cardImagePositionThird {
  object-fit: cover;
  height: inherit;
}

.cardTextWrapper {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include screen-large {
    padding: 30px;
  }
}

.cardTextHeader {
  margin: 0;
  font-weight: $font-weight-demibold;
  font-size: 20px;
  text-align: left;

  @include screen-large {
    font-size: 22px;
  }
}

.cardTextParagraph {
  margin-block-start: 10px;
  font-size: 16px;
  text-align: left;
}
