/* stylelint-disable no-descending-specificity */

@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

$radio-size: 24px;
$margin-label: 5px;
$margin-label-container: 15px;

.nv-radio-icon {
  border: 1px solid $cooledCarbon;
  border-radius: 50%;
  display: block;
  block-size: $radio-size;
  inline-size: $radio-size;
  min-block-size: $radio-size;
  min-inline-size: $radio-size;
  margin-inline-end: $margin-label;
  background-color: $white;
  position: relative;
  cursor: pointer;

  &--selected {
    border-color: $deepTeal;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin-block: auto;
      margin-inline: auto;
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $deepTeal;
    }
  }

  &--disabled {
    background-color: $gray3;
    border-color: $gray3;

    &.nv-radio-icon--selected::after {
      background-color: $gray3;
    }
  }

  &:not(.nv-radio-icon--disabled):focus {
    box-shadow:
      0 0 0 2px $white,
      0 0 0 4px $cooledCarbon;
  }
}

.nv-radio-button {
  &-wrapper {
    &[aria-disabled='false'] {
      &:focus {
        .nv-radio-icon {
          box-shadow:
            0 0 0 2px $white,
            0 0 0 4px $cooledCarbon;
        }
      }

      label {
        cursor: pointer;
      }

      cursor: pointer;
    }

    &:hover,
    &:focus,
    &:active:focus {
      outline: none;
    }
  }

  &--inline {
    align-items: flex-start;
    display: inline-flex;

    &:not(:last-child) {
      margin-inline-end: 20px;
    }
  }

  &--container {
    padding-block: 20px;
    padding-inline: 20px;
    display: block;
    border: 1px solid $gray3;
    border-radius: 5px;
    min-inline-size: 100%;

    @include screen-medium {
      padding-block: 30px;
      padding-inline: 30px;
    }

    &:not(:last-child) {
      margin-block-end: 30px;
    }

    &[aria-checked='true'][aria-disabled='false'] {
      border-color: $deepTeal;
    }

    .nv-radio-icon {
      margin-inline-end: $margin-label-container;
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;

    &-label {
      display: flex;
      justify-content: space-between;
      inline-size: 100%;

      .additional-label {
        font-weight: $font-weight-demibold;
      }
    }
  }

  &-content-helper-text {
    margin-block-start: 5px;
    padding-inline-start: $radio-size + $margin-label-container;
    inline-size: 100%;
    display: flex;
    justify-content: space-between;

    .helper-text {
      @include screen-medium {
        max-inline-size: 80%;
      }

      color: $cooledCarbon;
      max-inline-size: 60%;
    }

    .additional-text {
      color: $deepTeal;
    }
  }
}

.nv-form-control--error {
  .nv-radio-icon:not(.nv-radio-icon--selected),
  .nv-radio-button--container {
    border-color: $error;
  }
}
