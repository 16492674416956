@use './colors.scss';

.nv-tags {
  &-item:not(:last-child) {
    margin-block-end: 16px;
  }

  &-label {
    color: colors.$ocean;
  }

  &-tag {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    background-color: colors.$neutralBackground;
    border-radius: 3px;
    margin-inline-end: 10px;
    margin-block: 5px;
  }

  &-remove-button {
    inline-size: 12px;
    block-size: 12px;
    cursor: pointer;
    color: colors.$ocean;
    display: inline-flex;
    align-items: center;
    font-size: 24px;
    margin-inline-start: 8px;
  }

  &-small.nv-tags-tag {
    padding: 3px 12px;

    > .nv-tags-label {
      font-size: 14px;
    }

    > .nv-tags-remove-button {
      font-size: 18px;
      inline-size: 10px;
      block-size: 10px;
    }
  }

  &-clear-button {
    margin-inline-start: 10px;
  }

  &-wrapper li {
    display: inline-flex;
  }

  &-modal {
    max-inline-size: 100%;
    inline-size: 400px;

    .nv-modal-body-wrapper {
      padding: 4px 30px;
    }
  }
}
