@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.cardCheckbox {
  background: $gray7;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  @include screen-large {
    padding: 10px 30px;
  }

  .requiredItemWrapper {
    display: flex;
    align-items: center;
  }

  .requiredItemIcon {
    height: 14px;
    max-width: 20px;
    min-width: 20px;
  }

  .checkboxTitle {
    margin-left: 15px;
    font-weight: $font-weight-medium;
  }

  .title {
    font-weight: $font-weight-medium;
  }
}

.cardCheckboxClickable {
  &:hover {
    background: $gray5;
  }
}
